import { CSSProperties, MouseEventHandler } from 'react';
import { sprintf } from 'sprintf-js';

import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { Color, colors } from 'theme/theme';

import { AvatarContainer } from './Avatar.styled';

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const BORDER_WIDTHS = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
} as const satisfies Record<AvatarSize, number>;

const AVATAR_SIZES = {
  xs: 38,
  sm: 60,
  md: 84,
  lg: 150,
  xl: 192,
} as const satisfies Record<AvatarSize, number>;

type Props = {
  image: ApiFilestackImageData;
  name: string;
  size: AvatarSize;
  border?: Color;
  eagerLoad?: boolean;
  imageQaId?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function Avatar({
  image,
  name,
  size,
  border,
  eagerLoad,
  imageQaId,
  style = {},
  onClick,
}: Props) {
  return (
    <AvatarContainer
      style={{
        border: border
          ? `${BORDER_WIDTHS[size]}px solid ${colors[border]}`
          : undefined,
        ...style,
      }}
      onClick={onClick}
    >
      <FilestackImage
        alt={sprintf(getText('%(name)s profile image'), {
          name,
        })}
        data={image}
        width={AVATAR_SIZES[size]}
        height={AVATAR_SIZES[size]}
        objectFit="cover"
        round
        eagerLoad={eagerLoad}
        skeleton
        data-qa-id={imageQaId}
      />
    </AvatarContainer>
  );
}
