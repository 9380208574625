import type { SearchResponse } from '@algolia/client-search';
import { useMemo } from 'react';
import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box/Box';
import { Divider } from 'components/Divider/Divider';
import { Pagination } from 'components/Pagination/Pagination';
import { BodySmall } from 'components/Text/BodySmall';
import { CmsApiCategory } from 'modules/cms/api/types/CmsApiCategory';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { PostSearchAlgoliaHit } from 'modules/postSearch/algolia/types/PostSearchAlgoliaHit';
import { PostSearchLoadingResults } from 'modules/postSearch/components/Loading/PostSearchLoadingResults';
import { PostSearchResult } from 'modules/postSearch/components/Result/PostSearchResult';
import { scrollToTop } from 'utils/scroll';

import { PostSearchEmptyResultsMessage } from './PostSearchEmptyResultsMessage';

type Props = {
  homePageUrl: string;
  results: SearchResponse<PostSearchAlgoliaHit> | undefined;
  showFeaturedSearch: boolean;
  subsite: CmsApiSubsite;
  categories: CmsApiCategory[] | undefined;
  onPageIndexChange: (pageIndex: number) => void;
};

export function PostSearchResults({
  homePageUrl,
  results,
  showFeaturedSearch,
  subsite,
  categories,
  onPageIndexChange,
}: Props) {
  const categoryNameBySlug = useMemo(() => {
    const record: Record<string, string> = {};

    categories?.forEach((category) => {
      record[category.slug] = category.name;
    });

    return record;
  }, [categories]);

  if (!results) return <PostSearchLoadingResults />;

  const { hits, nbHits, nbPages, hitsPerPage, page } = results;
  if (hits.length === 0) return <PostSearchEmptyResultsMessage />;

  const remainingHits = showFeaturedSearch ? hits.slice(1) : hits;

  return (
    <>
      <Box pb={16} mb={16}>
        <BodySmall>
          {sprintf(getText('%(first)s-%(last)s of %(total)s %(word)s'), {
            first: (page * hitsPerPage + 1).toLocaleString(),
            last: Math.min((page + 1) * hitsPerPage, nbHits).toLocaleString(),
            total: nbHits.toLocaleString(),
            word: hits.length > 1 ? getText('articles') : getText('article'),
          })}
        </BodySmall>
      </Box>

      {showFeaturedSearch && (
        <>
          <PostSearchResult
            post={hits[0]}
            homePageUrl={homePageUrl}
            featured
            categoryNameBySlug={categoryNameBySlug}
            index={0}
            hitsPerPage={hitsPerPage}
            page={page}
            queryID={results.queryID}
            subsite={subsite}
            data-qa-id="search-result"
          />

          {hits.length > 1 && (
            <Box my={[24, null, null, 70]}>
              <Divider marginTop={0} marginBottom={0} />
            </Box>
          )}
        </>
      )}

      <Box
        mt={showFeaturedSearch ? 0 : -24}
        mx={-24}
        display="flex"
        flexWrap="wrap"
      >
        {remainingHits.map((hit, index) => (
          <Box p={24} key={hit.objectID} width={[1, 1 / 2]} flex="0 0 auto">
            <PostSearchResult
              post={hit}
              homePageUrl={homePageUrl}
              categoryNameBySlug={categoryNameBySlug}
              index={index}
              hitsPerPage={hitsPerPage}
              page={page}
              queryID={results.queryID}
              subsite={subsite}
              data-qa-id="search-result"
            />
          </Box>
        ))}
      </Box>

      <Pagination
        nbHits={nbHits}
        nbPages={nbPages}
        pageIndex={page}
        hitsPerPage={hitsPerPage}
        onChangePageIndex={(pageIndex) => {
          onPageIndexChange(pageIndex);
          scrollToTop();
        }}
      />
    </>
  );
}
