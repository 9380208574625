import { ReactNode } from 'react';

import { Box } from 'components/Box/Box';
import { Divider } from 'components/Divider/Divider';
import { SearchLoadingSectionLine } from 'modules/search/components/Loading/SearchLoadingSectionLine';

export function PostSearchLoadingResults() {
  return (
    <>
      <div>
        <SearchLoadingSectionLine pb="56.25%" borderRadius={24} />
        <SearchLoadingSectionLine mt={36} height={28} width="75%" />
        <SearchLoadingSectionLine mt={24} height={12} />
      </div>

      <Divider marginTop={48} marginBottom={72} />

      <div style={{ display: 'flex', flexWrap: 'wrap', margin: '24px -24px' }}>
        {new Array(10).fill(null).map<ReactNode>((item, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            width={[1, null, 1 / 2]}
            flex="0 0 auto"
            p={24}
          >
            <SearchLoadingSectionLine pb="56.25%" borderRadius={24} />
            <SearchLoadingSectionLine mt={28} height={20} width="75%" />
            <SearchLoadingSectionLine mt={12} height={12} />
          </Box>
        ))}
      </div>
    </>
  );
}
