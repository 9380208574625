import { useMemo } from 'react';
import { sprintf } from 'sprintf-js';

import { Button } from 'components/Button/Button';
import { Divider } from 'components/Divider/Divider';
import { Pill } from 'components/Pill/Pill';
import { BodySmall } from 'components/Text/BodySmall';
import { Text } from 'components/Text/Text';
import { CmsApiCategory } from 'modules/cms/api/types/CmsApiCategory';
import { CmsApiCategorySlug } from 'modules/cms/api/types/CmsApiTypedId';

import {
  PillHolder,
  PostSearchSideFacetFilterHolder,
  PostSearchSideFacetFilterRelatedHolder,
  SearchFilterSectionFilters,
} from './PostSearchCategories.styled';
import { PostSearchCategory } from './PostSearchCategory';

type Props = {
  label: string | null;
  allCategories: CmsApiCategory[] | undefined;
  selectedCategorySlugs: string[];
  onSelectParentCategory: (categorySlug: CmsApiCategorySlug) => void;
  onDeselectParentCategory: () => void;
  onSelectSubCategory: (categorySlug: CmsApiCategorySlug) => void;
  onDeselectSubCategory: (categorySlug: CmsApiCategorySlug) => void;
};

export function PostSearchCategoryFilter({
  label,
  allCategories,
  selectedCategorySlugs,
  onSelectParentCategory,
  onDeselectParentCategory,
  onSelectSubCategory,
  onDeselectSubCategory,
}: Props) {
  const parentCategories =
    allCategories?.filter((category) => !category.parentId) || [];

  const selectedParentCategory = useMemo(() => {
    if (!allCategories) return;

    const selectedCategories = allCategories.filter((category) =>
      selectedCategorySlugs.includes(category.slug),
    );

    if (selectedCategories.length === 0) return;

    const firstItemParentId = selectedCategories[0]?.parentId;
    if (!firstItemParentId) return selectedCategories[0];

    return allCategories.find((category) => category.id === firstItemParentId);
  }, [allCategories, selectedCategorySlugs]);

  const subcategories =
    allCategories?.filter(
      (category) => category.parentId === selectedParentCategory?.id,
    ) || [];

  return (
    <div data-qa-id="side-filter-facet-categories">
      <PostSearchSideFacetFilterHolder>
        <BodySmall>{label || getText('Category')}</BodySmall>

        {selectedCategorySlugs.length > 0 && (
          <Button
            type="button"
            variant="tertiary"
            onClick={onDeselectParentCategory}
          >
            <Text styleAs="h5">
              {
                /* TRANSLATORS: clear all filters in article search */
                getText('Clear')
              }
            </Text>
          </Button>
        )}
      </PostSearchSideFacetFilterHolder>

      <SearchFilterSectionFilters data-qa-id="search-filter-section-filters">
        {parentCategories.map((parentCategory) => {
          const selected = parentCategory.id === selectedParentCategory?.id;

          return (
            <PostSearchCategory
              key={parentCategory.slug}
              selected={selected}
              title={parentCategory.name}
              icon={parentCategory.icon}
              onClick={() =>
                selected
                  ? onDeselectParentCategory()
                  : onSelectParentCategory(parentCategory.slug)
              }
              qaId="side-filter"
              data-qa-selected={selected}
              data-qa-value={parentCategory.slug}
            />
          );
        })}
      </SearchFilterSectionFilters>

      {subcategories.length > 0 && (
        <>
          <Divider marginTop={36} marginBottom={36} />

          <PostSearchSideFacetFilterHolder>
            <BodySmall>{getText('Related')}</BodySmall>
          </PostSearchSideFacetFilterHolder>

          <PostSearchSideFacetFilterRelatedHolder>
            {subcategories.map((subcategory) => {
              const selected = selectedCategorySlugs.includes(subcategory.slug);

              return (
                <PillHolder key={subcategory.id}>
                  <Pill
                    onClick={() =>
                      selected
                        ? onDeselectSubCategory(subcategory.slug)
                        : onSelectSubCategory(subcategory.slug)
                    }
                    title={sprintf(getText(`Clear %(option)s filter`), {
                      option: subcategory.name,
                    })}
                    text={subcategory.name}
                    active={selected}
                    $outline
                  />
                </PillHolder>
              );
            })}
          </PostSearchSideFacetFilterRelatedHolder>
        </>
      )}
    </div>
  );
}
