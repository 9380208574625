import { Box } from 'components/Box/Box';
import { Byline } from 'components/Byline/Byline';
import { BodySmall } from 'components/Text/BodySmall';
import { H2 } from 'components/Text/H2';
import { H3 } from 'components/Text/H3';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { CmsPostPageLocationState } from 'modules/cms/pages/Post/CmsPostPageLocationState';
import { PostSearchAlgoliaHit } from 'modules/postSearch/algolia/types/PostSearchAlgoliaHit';
import { gutterWidth } from 'theme/theme';
import { algoliaTrackClick } from 'utils/algolia/algoliaTrackClick';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { ImageLink, PostLink } from './PostSearchResult.styled';
import { PostSearchResultCategoryLabel } from './PostSearchResultCategoryLabel';
import { PostSearchResultImage } from './PostSearchResultImage';

type Props = {
  post: PostSearchAlgoliaHit;
  subsite: CmsApiSubsite;
  homePageUrl: string;
  featured?: boolean;
  categoryNameBySlug: Record<string, string>;
  index: number;
  queryID: string | undefined;
  hitsPerPage: number;
  page: number;
  'data-qa-id': string;
};

export function PostSearchResult({
  post,
  subsite,
  homePageUrl,
  featured = false,
  categoryNameBySlug,
  index,
  queryID,
  hitsPerPage,
  page,
  'data-qa-id': qaId,
}: Props) {
  const Title = featured ? H2 : H3;

  const track = () => {
    algoliaTrackClick(
      queryID,
      post.objectID, // Position in algolia analytics is 1-indexed
      hitsPerPage ? page * hitsPerPage + 1 : 1,
    );

    trackClicked('Search Result', {
      result_id: post.objectID,
      result_index: index,
      result_type: post.type,
    });
  };

  const description = post.metaDescription;

  const truncatedDescription =
    description && description.length > 144
      ? [
          description.slice(0, 144).replace(/\.$/, ''),
          description.slice(144).split(' ')[0],
          description.slice(144).split(' ').length > 1 ? '&hellip;' : '',
        ].join('')
      : description;

  const postSubsite =
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    subsite.includedSubsites && subsite.includedSubsites.length
      ? subsite.includedSubsites.find((s) => post.subsiteId.includes(s.id))
      : null;

  return (
    <div data-qa-id={qaId}>
      <ImageLink
        to={`/${post.url}`}
        onClick={track}
        tabIndex={-1}
        state={{ subsite } satisfies CmsPostPageLocationState}
      >
        <Box
          overflow="hidden"
          // @ts-expect-error TS(2322): Type 'false | number[]' is not assignable to type ... Remove this comment to see the full error message
          mx={featured && [-gutterWidth / 2, 0]}
          borderRadius={featured ? [0, 24] : 10}
          lineHeight={0}
        >
          <PostSearchResultImage
            subsite={subsite}
            post={post}
            featured={featured}
          />
        </Box>
      </ImageLink>

      {postSubsite ? (
        <PostSearchResultCategoryLabel>
          {postSubsite.publicName}
        </PostSearchResultCategoryLabel>
      ) : (
        post.categoriesForCard && (
          <PostSearchResultCategoryLabel>
            {post.categoriesForCard
              .map((category) => categoryNameBySlug[category] || '')
              .join(', ')}
          </PostSearchResultCategoryLabel>
        )
      )}

      <Title mt={post.categoriesForCard ? 0 : 24} mb={10}>
        <PostLink
          onClick={track}
          data-qa-id="search-result-link"
          to={`/${post.url}`}
          state={{ subsite } satisfies CmsPostPageLocationState}
        >
          {post.title}
        </PostLink>
      </Title>
      {featured && description && (
        <BodySmall
          mb={featured ? 24 : 12}
          dangerouslySetInnerHTML={{
            // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
            __html: truncatedDescription,
          }}
        />
      )}

      {(post.author || post.postedDate) && (
        <Box mt={featured ? 24 : 12}>
          <Byline
            slug={post.author ? post.author.slug : undefined}
            name={post.author ? post.author.name : undefined}
            image={
              featured && post.author
                ? {
                    handle: post.author.image.handle,
                    size: 0,
                    source: '',
                    filename: '',
                    mimetype: '',
                  }
                : undefined
            }
            homePageUrl={homePageUrl}
            postedDate={post.postedDate}
            showDate={post.showDate}
            small
          />
        </Box>
      )}
    </div>
  );
}
