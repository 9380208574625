import { Button } from 'components/Button/Button';
import { CmsApiLink } from 'modules/cms/api/types/CmsApiLink';
import { CmsButton } from 'modules/cms/components/Button/CmsButton';

type Props = {
  searchLink: CmsApiLink;
};

export function PostSearchSidebarSearchLink({ searchLink }: Props) {
  return (
    <CmsButton
      buttonData={searchLink}
      eventProperties={{
        search_link_button_type: searchLink.type,
      }}
      render={({ data, children, title, 'data-qa-id': qaId }) => (
        <Button
          data={data}
          title={title}
          variant="tertiary"
          noPadding
          data-qa-id={qaId}
        >
          {children}
        </Button>
      )}
    />
  );
}
